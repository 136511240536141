import React, { useContext, useEffect } from 'react';
import { TxnContext } from '../../contexts';

import { DashboardSummary } from '../../legos/dashboard/DashboardSummary';
import { DashboardTxnDetails } from '../../legos/dashboard/DashboardTxnDetails';
import { MenuDesktop } from '../../template/MenuDesktop';
import { AddTxnDesktop } from '../desktop/txn-add-web/AddTxnDesktop';
import { Route } from 'react-router-dom';
import Header from '../../template/Header';

export const Dashboard = () => {
    const { getDailySummary } = useContext(TxnContext);

    useEffect(() => {
        getDailySummary();
    }, [getDailySummary]);

    return (
        <section className={'h-screen overflow-y-auto col-span-2'}>
            <Header />
            <DashboardSummary />
            <DashboardTxnDetails className={'pb-24 px-6'} />
        </section>
    );
};
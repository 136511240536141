import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { RichSelect } from '../../../template/RichSelect';
import { CategoriesSelect } from '../../../extended/RichSelect/CategoriesSelect';
import { SourcesSelect } from '../../../extended/RichSelect/SourcesSelect';
import { getCached } from '../../../../use-cases/cache/get-cached';
import { NumPad } from '../../../template/NumPad';
import { handleSubmit } from '../../../../use-cases/service-handlers/base-service-handler';
import Modal from '../../../template/Modal';

import { SiteSettingsContext, TxnContext } from '../../../contexts';
import { Calculator } from '../../../extended/modals/Calculator';
import { AddReimbursement } from '../../../extended/modals/AddReimbursement';

import '../../../template/common.scss';

export const AddTxn = () => {
    const navigate = useNavigate();

    const { txn, setTxn, resetTxn, getDailySummary, summary } = React.useContext(TxnContext);
    const { settings, onChangeSettings } = useContext(SiteSettingsContext);

    const sources = [
        { id: 1, name: 'NSB', unavailable: false, longName: 'National Savings Bank', emoji: '🏦' },
        { id: 2, name: 'BOC CC', unavailable: false, longName: 'BOC Credit Card', emoji: '💳' },
        { id: 3, name: 'Cash', unavailable: false, longName: 'Cash', emoji: '💵' },
        { id: 4, name: 'FD', unavailable: true, longName: 'Fixed Deposit', emoji: '📜' },
    ];
    const isTouch = 'ontouchstart' in window;

    const [loading, setLoading] = useState(false);
    const [isCalcOpen, setIsCalcOpen] = useState(false);
    const [calcOut, setCalcOut] = useState('');
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [isReimOpen, setIsReimOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState({
        title: 'Required fields',
        message: '',
        type: 'error',
    });
    const [cats, setCats] = useState([]);
    const [displayAmount, setDisplayAmount] = useState('');

    const [showCat, setShowCat] = useState(0);
    const [showSources, setShowSources] = useState(0);

    const [inputs, setInputs] = useState(txn);
    const [selectedCatIndex, setSelectedCatIndex] = useState(0);
    const [selectedSourceIndex, setSelectedSourceIndex] = useState(0);

    useEffect(() => {
        getCached('categories', 'categories').then(res => {
            let selectedIndex = res.findIndex(x => parseInt(x.id) === parseInt(txn.tx_cat));

            setCats(res);
            setSelectedCatIndex(selectedIndex);
            setInputs({
                ...inputs,
                tx_cat: res[selectedIndex].id ?? 1,
            });
        });

        setSelectedSourceIndex(sources.findIndex(x => x.id === txn.source));
        formatDisplayAmount(txn.tx_amount.toString());
    }, []);

    useEffect(() => {
        setShowSources(2);

        setInputs({
            ...inputs,
            source: sources[selectedSourceIndex].id ?? 1,
        });
    }, [selectedSourceIndex]);

    useEffect(() => {
        setShowCat(2);

        if (typeof cats[selectedCatIndex] !== 'undefined') {
            setInputs({
                ...inputs,
                tx_cat: cats[selectedCatIndex].id ?? 1,
            });
        }
    }, [selectedCatIndex]);


    const formatDisplayAmount = (num) => {
        if (num.toString().indexOf('.') !== -1) {
            let num_parts = num.split('.');
            const num_part1 = numberWithCommas(num_parts[0]);
            setDisplayAmount(num_part1 + '.' + num_parts[1].padEnd(2, '0'));
        } else {
            setDisplayAmount(numberWithCommas(num));
        }

        setInputs({
            ...inputs,
            tx_amount: parseFloat(num),
        });
    };

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    };

    const handleAmtInput = (e) => {
        setInputs({
            ...inputs,
            tx_amount: e.target.value,
        });
    };

    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value,
        });
    };

    const toggleTxType = () => {
        setInputs({
            ...inputs,
            tx_type: inputs.tx_type ? 0 : 1,
        });
    };

    const txError = (err) => {
        setLoading(false);
        getDailySummary();

        setModalMessage({
            title: 'Error',
            message: typeof err.message !== 'undefined' ? err.message : err.toString(),
            type: 'error',
        });

        setShowMessageModal(true);
    };

    const closeMessageModal = () => {
        setShowMessageModal(false);
    };

    const submit = () => {
        if (inputs.description !== '' && inputs.tx_amount !== '') {
            setLoading(true);
            handleSubmit('transaction', inputs.method, inputs, () => {
                setLoading(true);
                getDailySummary();
                resetTxn();
                navigate(-1);
            }, txError);
        } else {
            setModalMessage({ type: 'error', title: 'Required fields', message: 'Please add a description and/or amount' });
            setShowMessageModal(true);
        }
    };

    const deleteTxn = () => {
        if (window.confirm('Are sure want to delete this transaction?')) {
            if (inputs.id) {
                setLoading(true);

                handleSubmit('transaction/' + inputs.id, 'delete', inputs, () => {
                    setLoading(false);
                    getDailySummary();
                    resetTxn();
                    navigate(-1);
                }, txError);
            }
        }
    };

    return (
        <div className={'sm:w-2/3 md:w-1/2 2xl:w-1/3 mx-auto no-select overflow-hidden'}>
            <div className={'flex justify-between items-center sticky top-0 z-10 bg-white dark:bg-gray-800'}>
                <div className={'flex items-center'} onClick={() => {
                    navigate(-1);
                    resetTxn();
                }}>
                    <i className={'la la-times la-2x p-3'} />
                </div>

                <div className={'flex items-center'}>
                    {(typeof inputs.id !== 'undefined' && inputs.id !== null && inputs.id !== 0 && inputs.id !== '') &&
                        <i className={'la la-trash text-red-700 dark:text-red-200 py-1 px-6 dark:bg-red-900 bg-red-400/40 rounded-full mr-4 text-xl'} onClick={deleteTxn} />}
                    <i className={'la la-sort-numeric-down py-1 px-2 rounded-full mr-4 text-xl ' + (settings.numPad === 'calc' ? 'bg-gray-300 dark:bg-gray-500' : 'dark:bg-gray-700 bg-gray-200')}
                       onClick={() => onChangeSettings({ numPad: settings.numPad === 'calc' ? 'phone' : 'calc' })} />
                </div>
            </div>

            <header className={'text-center pt-8 pb-8 text-xl'}>{typeof inputs.id !== 'undefined' ? 'Edit' : 'Add'} Transaction {typeof inputs.id !== 'undefined' ? '(#' + inputs.id + ')' : ''}</header>

            <section className={'flex justify-center items-end pt-12'}>
                <i className={'la text-2xl px-4 py-3 rounded-lg ' + (inputs.tx_type ? 'la-upload bg-red-600/40' : 'la-download bg-cyan-700')} onClick={toggleTxType} />
                <div className={'border-b w-3/5 text-right ml-2 p-2 flex items-end justify-between'} style={{ height: '47px' }}>
                    <div className={'text-sm leading-none'}>LKR</div>
                    {
                        !isTouch ? <input className={'bg-transparent outline-0 px-3 text-3xl text-right w-full'} step=".01" pattern="^\d*(\.\d{0,2})?$" value={inputs.tx_amount} type={'number'} onChange={handleAmtInput} /> :
                            <div className={'text-3xl leading-none'}>{displayAmount}</div>
                    }
                </div>
                <span className="material-symbols-rounded p-2" onClick={() => setIsCalcOpen(true)}>calculate</span>
            </section>


            <footer className={'fixed bottom-0 px-3 sm:w-2/3 md:w-1/2 2xl:w-1/3 w-full mx-auto'}>
                <section className={'flex justify-between items-center py-3 text-sm'}>
                    <div className={'flex items-center gap-2'}>
                        <i className={'la la-list p-2 border border-gray-500 text-gray-500 rounded-full'} />
                        <div className={'flex items-center text-red-600 font-bold'}>
                            <span>LKR 88,888.00</span>
                        </div>
                    </div>
                    <button className={'border border-green-600 text-green-600 rounded-full px-5 py-1'} onClick={() => setIsReimOpen(true)}><i
                        className={'text-sm align-middle ' + (loading ? 'la la-refresh la-spin' : 'la la-download')}
                        disabled={loading} />
                        <span className={'align-middle text-xs'}>{loading ? 'Saving...' : 'Reimbursement'}</span>
                    </button>
                </section>
                <div className={'flex py-2'}>
                    <input type={'date'} name={'tx_date'} className={'bg-transparent text-xs focus:outline-0'}
                           onChange={handleChange} placeholder={'Date'} value={inputs.tx_date} />
                    <input type={'text'} name={'description'} className={'flex-1 bg-transparent text-xs focus:outline-0 p-2 transparent-autofill dark:bg-gray-800 dark:text-gray-100'} placeholder={'Description'} onChange={handleChange}
                           value={inputs.description} />
                </div>
                <div className={'flex p-2 items-center border-y dark:border-gray-700 border-gray-200'}>
                    <section className={'flex items-center justify-between ' + (inputs.tx_type ? 'flex-row' : 'flex-row-reverse')}>
                        <div className={'flex-1 cursor-pointer text-ellipsis overflow-ellipsis whitespace-nowrap'} id={'source-select-' + selectedSourceIndex} onClick={() => setShowSources(1)}>
                            <span>{sources[selectedSourceIndex].emoji}</span> <span className={'text-xs'}>{sources[selectedSourceIndex].name}</span>
                        </div>
                        <i className={'la la-arrow-right p-3'} />
                        <div className={'flex-1 cursor-pointer text-ellipsis overflow-ellipsis whitespace-nowrap'} onClick={() => setShowCat(1)}>
                            <span>{cats[selectedCatIndex] ? cats[selectedCatIndex].emoji : ''}</span> <span className={'text-xs'}>{cats[selectedCatIndex] ? cats[selectedCatIndex].category : ''}</span>
                        </div>
                    </section>
                    <div className={'text-right grow'}>
                        <button className={'bg-primary text-white rounded-full px-5 py-1'} onClick={submit}><i className={'text-xl align-middle ' + (loading ? 'la la-refresh la-spin' : 'la la-save')} disabled={loading} /> <span
                            className={'align-middle text-sm'}>{loading ? 'Saving...' : 'Save'}</span></button>
                    </div>
                </div>

                {isTouch && <NumPad setOutput={(num) => formatDisplayAmount(num)} />}
            </footer>

            <RichSelect isEnabled={showSources} toggleShow={setShowSources}>
                <SourcesSelect sources={sources} selectedChoice={selectedSourceIndex} setSelectedChoice={setSelectedSourceIndex} />
            </RichSelect>

            <RichSelect isEnabled={showCat} toggleShow={setShowCat}>
                <CategoriesSelect cats={cats} selectedChoice={selectedCatIndex} setSelectedChoice={setSelectedCatIndex} />
            </RichSelect>

            <Modal isOpen={showMessageModal} closeModal={closeMessageModal} title={modalMessage.title} type={modalMessage.type}>
                {modalMessage.message}
            </Modal>

            <Modal isOpen={isCalcOpen} closeModal={() => setIsCalcOpen(false)} title={'Calculator'}>
                <Calculator evalReturn={(num) => formatDisplayAmount(num)} calcOut={calcOut} setCalcOut={(e) => setCalcOut(e)} closeModal={() => setIsCalcOpen(false)} />
            </Modal>

            <Modal isOpen={isReimOpen} closeModal={() => setIsReimOpen(false)} title={'Reimbursement'}>
                <AddReimbursement />
            </Modal>
        </div>
    );
};
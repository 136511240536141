import Header from '../../../template/Header';
import Footer from '../../../template/Footer';
import { DropDown } from '../../../template/DropDown';
import { useEffect, useState } from 'react';
import { isoLocalDate } from '../../../../helpers/Helpers';

export const TransferFunds = () => {
    const sources = [
        { id: 1, name: 'NSB', unavailable: false },
        { id: 2, name: 'Cash', unavailable: false },
        { id: 3, name: 'FD', unavailable: true },
        { id: 4, name: 'BOC CC', unavailable: false },
    ];

    const [inputs, setInputs] = useState({
        fd_amount: '',
        fd_duration: '',
        fd_interest: '',
        fd_date: '0000-00-00',
    });

    const [calc, setCalc] = useState({
        finalAmt: 0,
        interest: 0,
        mer: 0,
        matDate: new Date().toLocaleDateString(),
    });

    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (inputs.fd_amount !== '' && inputs.fd_duration !== '' && inputs.fd_interest !== '') {
            let mer = parseFloat(inputs.fd_amount) * (parseFloat(inputs.fd_interest) / 100) / 12;
            let interest = mer * parseInt(inputs.fd_duration);
            let finalAmount = parseFloat(inputs.fd_amount) + interest;

            setCalc({
                ...calc,
                finalAmt: finalAmount,
                interest: interest,
                mer: mer
            })
        }
    }, [inputs]);

    useEffect(() => {
        if (inputs.fd_date !== '0000-00-00') {
            let date = new Date(inputs.fd_date);
            date.setMonth(date.getMonth() + parseInt(inputs.fd_duration));
            console.log(date.toLocaleDateString())

            setCalc({
                ...calc,
                matDate: date
            });
        }
    }, [inputs.fd_date])

    return (
        <div className={'sm:w-2/3 md:w-1/2 2xl:w-1/3 mx-auto'}>
            <Header />
            <h1 className={'text-center pt-12 pb-8 text-2xl'}>Term Savings</h1>
            <section className={'flex items-center justify-between px-3'}>
                <div className={'flex-1'}>
                    <DropDown list={sources} />
                </div>
                <i className={'la la-arrow-right p-3'} />
                <div className={'flex-1'}>
                    <DropDown list={sources} />
                </div>
            </section>
            <section>
                <div className={'py-12 w-4/5 mx-auto'}>
                    <div className={'flex items-end border-b-2 border-gray-100 dark:border-gray-700 px-4'}>
                        <span className={'text-gray-500 pb-1 text-sm'}>LKR</span>
                        <input type={'number'} step={'0.01'} className={'text-3xl text-right py-1 bg-transparent w-full flex-shrink focus:outline-0'} name={'fd_amount'} placeholder={'000,000.00'} onChange={handleChange} value={inputs.fd_amount} />
                    </div>
                </div>
                <div className={'flex w-4/5 mx-auto gap-6'}>
                    <div className={'text-center'}>
                        <input type={'number'} className={'text-xl text-center px-3 py-1 bg-transparent w-full focus:outline-0 border-b-2 border-gray-100 dark:border-gray-700'} name={'fd_duration'} placeholder={'12'} onChange={handleChange} value={inputs.fd_duration} />
                        <label className={'text-xs'}>Duration (Months)</label>
                    </div>
                    <div className={'text-center'}>
                        <input type={'number'} step={'0.01'} className={'text-xl text-center px-3 py-1 bg-transparent w-full focus:outline-0 border-b-2 border-gray-100 dark:border-gray-700'} name={'fd_interest'} onChange={handleChange} value={inputs.fd_interest} placeholder={'12%'} />
                        <label className={'text-xs'}>Interest per annum</label>
                    </div>
                </div>
                <div className={'text-center pt-12 pb-3'}>
                    <h3 className={'text-xs'}>Final Amount</h3>
                    <h4 className={'text-3xl font-bold text-green-600'}>{calc.finalAmt.lkr()}</h4>
                    <span className={'text-xs text-gray-700 dark:text-gray-400'}>By {isoLocalDate(calc.matDate)}</span>
                </div>
                <div className={'flex gap-6 w-4/5 mx-auto justify-around pt-3 pb-12'}>
                    <div className={'text-center'}>
                        <h3 className={'text-xs'}>Interest</h3>
                        <h4 className={'text-xl'}>{calc.interest.lkr()}</h4>
                    </div>
                    <div className={'text-center'}>
                        <h3 className={'text-xs'}>MER</h3>
                        <h4 className={'text-xl'}>{calc.mer.lkr()}</h4>
                    </div>
                </div>
                {/*<div>
                    <span className="material-symbols-rounded">
                        settings
                    </span>
                </div>*/}
            </section>
            <footer className={'fixed bottom-0 px-3 sm:w-2/3 md:w-1/2 2xl:w-1/3 w-full mx-auto'}>
                <div className={'flex py-2'}>
                    <input type={'date'} name={'fd_date'} className={'bg-transparent text-xs focus:outline-0'} onChange={handleChange} placeholder={'Date'} value={inputs.fd_date} />
                    <input type={'text'} name={'fd_bank'} className={'flex-1 bg-transparent text-xs focus:outline-0'} placeholder={'National Savings Bank'} onChange={handleChange} />
                </div>
                <div className={'flex py-2 items-end'}>
                    <div className={'grow-0'}>
                        <div className={'text-xs'}>Account No</div>
                        <input type={'text'} name={'fd_acc_no'} className={'bg-transparent text-xs w-24 focus:outline-0'} placeholder={'101020278742'} onChange={handleChange} />
                    </div>
                    <div className={'text-center px-3 text-xs'}>/</div>
                    <div className={'grow-0'}>
                        <div className={'text-xs'}>Certificate No</div>
                        <input type={'text'} name={'fd_cert_no'} className={'bg-transparent text-xs w-24 focus:outline-0'} placeholder={'101020278742'} onChange={handleChange} />
                    </div>
                    <div className={'text-right grow'}>
                        <button className={'bg-primary rounded-full text-xs px-5 py-2'}><i className={'la la-save'} /> Save</button>
                    </div>
                </div>
            </footer>
        </div>
    )
}
import { persistSessionStore } from '../stores/persit-session/persist-session-store';

export function useAuth(props) {
    const user = persistSessionStore.getState().user;
    
    if (user) {
        return props.roles.includes(user.role) || props.minlevel <= user.level;
    } else {
        return false;
    }
}

export function isLoggedIn() {
    const login = persistSessionStore.getState().isAuthenticated;
    return !!login;
}

export function createMarkup(content) {
    return { __html: content };
}

Number.prototype.lkr = function() {
    return this.toLocaleString('LK', {
        currency: 'LKR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        //style:'currency',
    });
};

export const isoLocalDate = (date) => {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0')
    );
};

export const relativeDay = (date) => {
    const now = new Date();
    const date_formatted = !(date instanceof Date) ? new Date(date) : date;

    if (
        now.getFullYear() === date_formatted.getFullYear() &&
        now.getMonth() === date_formatted.getMonth() &&
        now.getDate() - date_formatted.getDate() === 0
    ) {
        return 'Today';
    } else if (
        now.getFullYear() === date_formatted.getFullYear() &&
        now.getMonth() === date_formatted.getMonth() &&
        now.getDate() - date_formatted.getDate() === 1
    ) {
        return 'Yesterday';
    } else {
        return date;
    }
};

export const hello = [
    { lang: 'es', hello: 'Hola' },
    { lang: 'fr', hello: 'Bonjour' },
    { lang: 'ge', hello: 'Guten tag' },
    { lang: 'it', hello: 'Salve' },
    { lang: 'cn', hello: 'Nǐn hǎo' },
    { lang: 'pt', hello: 'Olá' },
    { lang: 'jp', hello: 'Konnichiwa' },
    { lang: 'kr', hello: 'Anyoung haseyo' },
    { lang: 'jp', hello: 'Konnichiwa' },
    { lang: 'ru', hello: 'Zdravstvuyte' },
    { lang: 'ne', hello: 'Hoi' },
    { lang: 'fi', hello: 'Hyvää päivää' },
    { lang: 'gr', hello: 'Yasou' },
    { lang: 'he', hello: 'Shalom' },
    { lang: 'is', hello: 'Góðan dag' },
];

export const genRandHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { RichSelect } from '../../../template/RichSelect';
import { CategoriesSelect } from '../../../extended/RichSelect/CategoriesSelect';
import { SourcesSelect } from '../../../extended/RichSelect/SourcesSelect';
import { getCached } from '../../../../use-cases/cache/get-cached';
import { NumPad } from '../../../template/NumPad';
import { handleSubmit } from '../../../../use-cases/service-handlers/base-service-handler';
import Modal from '../../../template/Modal';

import { TxnContext } from '../../../contexts';

import '../../../template/common.scss';

export const AddTxnDesktop = () => {
    const navigate = useNavigate();
    const { txn, setTxn, resetTxn, getDailySummary, summary } = React.useContext(TxnContext);

    const sources = [
        { id: 1, name: 'NSB', unavailable: false, longName: 'National Savings Bank', emoji: '🏦' },
        { id: 2, name: 'BOC CC', unavailable: false, longName: 'BOC Credit Card', emoji: '💳' },
        { id: 3, name: 'Cash', unavailable: false, longName: 'Cash', emoji: '💵' },
        { id: 4, name: 'FD', unavailable: true, longName: 'Fixed Deposit', emoji: '📜' },
    ];
    const isTouch = 'ontouchstart' in window;

    const [loading, setLoading] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [modalMessage, setModalMessage] = useState({
        title: 'Required fields',
        message: '',
        type: 'error',
    });
    const [cats, setCats] = useState([]);
    const [displayAmount, setDisplayAmount] = useState('');

    const [showCat, setShowCat] = useState(0);
    const [showSources, setShowSources] = useState(0);

    const [inputs, setInputs] = useState(txn);
    const [selectedCatIndex, setSelectedCatIndex] = useState(0);
    const [selectedSourceIndex, setSelectedSourceIndex] = useState(0);

    useEffect(() => {
        getCached('categories', 'categories').then(res => {
            let selectedIndex = res.findIndex(x => parseInt(x.id) === parseInt(txn.tx_cat));

            setCats(res);
            setSelectedCatIndex(selectedIndex);
            setInputs({
                ...inputs,
                tx_cat: res[selectedIndex].id ?? 1,
            });
        });

        setSelectedSourceIndex(sources.findIndex(x => x.id === txn.source));
        formatDisplayAmount(txn.tx_amount.toString());
    }, []);

    useEffect(() => {
        setSelectedCatIndex(cats.findIndex(x => parseInt(x.id) === parseInt(txn.tx_cat)));
        setSelectedSourceIndex(sources.findIndex(x => x.id === txn.source));
        setInputs({
            ...txn,
            tx_cat: typeof cats[selectedCatIndex] !== 'undefined' ? cats[selectedCatIndex].id : 1,
        });
    }, [txn]);

    useEffect(() => {
        setShowSources(2);

        setInputs({
            ...inputs,
            source: sources[selectedSourceIndex].id ?? 1,
        });
    }, [selectedSourceIndex]);

    useEffect(() => {
        setShowCat(2);

        if (typeof cats[selectedCatIndex] !== 'undefined') {
            setInputs({
                ...inputs,
                tx_cat: cats[selectedCatIndex].id ?? 1,
            });
        }
    }, [selectedCatIndex]);


    const formatDisplayAmount = (num) => {
        if (num.toString().indexOf('.') !== -1) {
            let num_parts = num.split('.');
            const num_part1 = numberWithCommas(num_parts[0]);
            setDisplayAmount(num_part1 + '.' + num_parts[1].padEnd(2, '0'));
        } else {
            setDisplayAmount(numberWithCommas(num));
        }

        setInputs({
            ...inputs,
            tx_amount: parseFloat(num),
        });
    };

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    };

    const handleAmtInput = (e) => {
        setInputs({
            ...inputs,
            tx_amount: e.target.value,
        });
    };

    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value,
        });
    };

    const toggleTxType = () => {
        setInputs({
            ...inputs,
            tx_type: inputs.tx_type ? 0 : 1,
        });
    };

    const toggleReturnables = (type) => {
        setInputs({
            ...inputs,
            loan_reim: type,
        });
    };

    const txError = (err) => {
        setLoading(false);
        getDailySummary();

        setModalMessage({
            title: 'Error',
            message: typeof err.message !== 'undefined' ? err.message : err.toString(),
            type: 'error',
        });

        setShowMessageModal(true);
    };

    const closeMessageModal = () => {
        setShowMessageModal(false);
    };

    const submit = () => {
        if (inputs.description !== '' && inputs.tx_amount !== '') {
            setLoading(true);
            handleSubmit('transaction', inputs.method, inputs, () => {
                setLoading(false);
                getDailySummary();
                resetTxn();
                // navigate(-1);
            }, txError);
        } else {
            setModalMessage({ type: 'error', title: 'Required fields', message: 'Please add a description and/or amount' });
            setShowMessageModal(true);
        }
    };

    const deleteTxn = () => {
        if (window.confirm('Are sure want to delete this transaction?')) {
            if (inputs.id) {
                setLoading(true);

                handleSubmit('transaction/' + inputs.id, 'delete', inputs, () => {
                    setLoading(false);
                    getDailySummary();
                    resetTxn();
                    // navigate(-1);
                }, txError);
            }
        }
    };

    return (
        <div className={'relative flex flex-1 flex-col h-full no-select'}>
            <article className={'flex flex-col justify-between flex-1'}>
                <header className={'text-center pt-8 text-xl'}>
                    {typeof inputs.id !== 'undefined' ? 'Edit' : 'Add'} Transaction {typeof inputs.id !== 'undefined' ? '(#' + inputs.id + ')' : ''}

                    <nav className={'flex justify-center items-center sticky top-0 z-10 bg-white dark:bg-gray-800 py-3'}>
                        {(typeof inputs.id !== 'undefined' && inputs.id !== null && inputs.id !== 0 && inputs.id !== '') &&
                            <i className={'la la-trash text-red-700 dark:text-red-200 py-1 px-6 mr-6 dark:bg-red-900 bg-red-400/40 rounded-full text-xl'} onClick={deleteTxn} />}
                    </nav>
                </header>
                <section className={'flex justify-center items-end'}>
                    <i className={'la  p-4 rounded-lg cursor-pointer ' + (inputs.tx_type ? 'la-upload bg-red-600/40' : 'la-download bg-primary-light dark:bg-primary-dark')}
                       onClick={toggleTxType} />
                    <div className={'border-b w-3/5 text-right ml-2 p-2 flex items-end justify-between'}
                         style={{ height: '47px' }}>
                        <div className={'text-sm leading-none'}>LKR</div>
                        {
                            !isTouch ?
                                <input className={'bg-transparent outline-0 px-3 text-3xl text-right w-full'} step=".01"
                                       pattern="^\d*(\.\d{0,2})?$" value={inputs.tx_amount} type={'number'}
                                       onChange={handleAmtInput} /> :
                                <div className={'text-3xl leading-none'}>{displayAmount}</div>
                        }
                    </div>
                </section>

                <section id={'category-select'} className={'flex items-center justify-center gap-6 ' + (inputs.tx_type ? 'flex-row' : 'flex-row-reverse')}>
                    <div className={'flex flex-col items-center'}
                         id={'source-select-' + selectedSourceIndex} onClick={() => setShowSources(1)}>
                        <div className={'flex flex-col items-center cursor-pointer bg-gray-200 dark:bg-gray-700 rounded-xl py-6 px-8 cursor-pointer overflow-ellipsis whitespace-nowrap'}>
                            <span>{sources[selectedSourceIndex].emoji}</span> <span
                            className={'text-xs'}>{sources[selectedSourceIndex].name}</span>
                        </div>
                    </div>
                    <i className={'la la-arrow-right p-3'} />
                    <div className={'flex flex-col items-center'}>
                        <div className={'flex flex-col items-center cursor-pointer bg-gray-200 dark:bg-gray-700 rounded-xl py-6 px-8 overflow-ellipsis whitespace-nowrap'} onClick={() => setShowCat(1)}>
                            <span>{cats[selectedCatIndex] ? cats[selectedCatIndex].emoji : ''}</span> <span
                            className={'text-xs'}>{cats[selectedCatIndex] ? cats[selectedCatIndex].category : ''}</span>
                        </div>
                    </div>
                </section>


                <div className={'flex flex-col py-4 px-4'}>
                    <section id={'returnables'} className={'flex justify-center py-3 gap-3 text-xs'}>
                        <div id={'loan'} className={'check-btn ' + (inputs.loan_reim === 1 ? 'checked' : '')} onClick={() => toggleReturnables(1)}><span className={'check-btn-circle'}></span>Loan</div>
                        <div id={'reimbursement'} className={'check-btn ' + (inputs.loan_reim === 2 ? 'checked' : '')} onClick={() => toggleReturnables(2)}><span className={'check-btn-circle'}></span>Reimbursement</div>
                        <div id={'clear'} className={'py-1 border border-transparent cursor-pointer'} onClick={() => toggleReturnables(0)}>Clear</div>
                    </section>

                    <input type={'date'} name={'tx_date'} className={'bg-transparent text-xs focus:outline-0 p-3 mb-3 border border-gray-200 dark:border-gray-700 rounded-xl'}
                           onChange={handleChange} placeholder={'Date'} value={inputs.tx_date} />
                    <textarea name={'description'}
                              className={'bg-transparent text-xs focus:outline-0 p-3 transparent-autofill dark:bg-gray-800 dark:text-gray-100 border border-gray-200 dark:border-gray-700 rounded-xl h-3 flex-1'}
                              placeholder={'Description'} onChange={handleChange} value={inputs.description} />
                    <span className={'text-xs text-right py-1 ' + (inputs.description.length > 20 ? 'text-red-600' : 'text-gray-400 dark:text-gray-500')}>{inputs.description.length}/256</span>
                </div>

                <footer className={'bottom-0 w-full flex flex-col px-6 pt-3 pb-12'}>
                    <div className={'flex flex-1 justify-center items-center dark:border-gray-700 border-gray-200 py-2'}>
                        <div className={'flex gap-3 items-center'}>
                            <button className={'rounded-full px-5 py-1'} onClick={() => {
                                resetTxn();
                            }} disabled={loading}>
                                <span className={'align-middle text-sm'}>New</span>
                            </button>
                            <button className={'flex gap-2 items-center bg-primary text-white rounded-full px-8 py-2'} onClick={submit} disabled={loading}>
                                <i className={'text-xl align-middle ' + (loading ? 'la la-refresh la-spin' : 'la la-save')} />
                                <span className={'align-middle text-sm'}>{loading ? 'Saving...' : 'Save'}</span>
                            </button>
                        </div>
                    </div>

                    {isTouch && <NumPad setOutput={(num) => formatDisplayAmount(num)} />}
                </footer>
            </article>

            <RichSelect isEnabled={showSources} toggleShow={setShowSources}>
                <SourcesSelect sources={sources} selectedChoice={selectedSourceIndex} setSelectedChoice={setSelectedSourceIndex} />
            </RichSelect>

            <RichSelect isEnabled={showCat} toggleShow={setShowCat}>
                <CategoriesSelect cats={cats} selectedChoice={selectedCatIndex} setSelectedChoice={setSelectedCatIndex} />
            </RichSelect>

            <Modal isOpen={showMessageModal} closeModal={closeMessageModal} title={modalMessage.title} type={modalMessage.type}>
                {modalMessage.message}
            </Modal>
        </div>
    );
};
import { Link, useLocation } from 'react-router-dom';
import { logout } from '../../use-cases/common/utils';

// This needs to be universal. Change styles over screen width.

const Menu = ({ toggleMenu }) => {
    const menuItems = [
        {
            text: 'Transfer Funds',
            link: '/transfer',
            icon: 'exchange-alt',
        },
        {
            text: 'Music Player',
            link: '/player',
            icon: 'music',
        },
        {
            text: 'Nuclear Test Site',
            link: '/test',
            icon: 'radiation-alt',
        },
        {
            text: 'Settings',
            link: '/settings',
            icon: 'cog',
        },
    ];

    const thisPage = useLocation();

    return (
        <div className={'sm:w-2/3 md:w-1/2 2xl:w-1/3 mx-auto relative bottom-20 w-full py-3 px-2 mb-1'}>
            <ul className={'items-center bg-gray-200 dark:bg-gray-700 rounded-3xl p-3'}>
                <div className={'text-left text-xl'}>
                    {menuItems.map((menuItem, index) => (
                        <Link key={index} to={menuItem.link}>
                            <li className={'p-3 rounded-2xl mb-2 ' + (thisPage.pathname === menuItem.link ? 'dark:bg-white/5 bg-black/5' : 'hover:dark:bg-white/5 hover:bg-black/5')}>
                                <i className={'la la-fw la-' + menuItem.icon} /> {menuItem.text}
                            </li>
                        </Link>
                    ))}
                    <li onClick={logout} className={'p-3 rounded-2xl mb-2 hover:dark:bg-white/5 hover:bg-black/5'}>
                        <i className={'la la-fw la-sign-out'} /> Logout
                    </li>
                </div>
            </ul>
        </div>
    );
};

export default Menu;

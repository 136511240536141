import Header from '../../../template/Header';
import CategoricalReport from './categorical/CategoricalReport';
import Footer from '../../../template/Footer';
import { useEffect, useState } from 'react';
import { SummaryReport } from './summary/SummaryReport';

export const Reports = () => {
    const isMobile = navigator.userAgentData.mobile;
    const [reportID, setReportID] = useState(1);
    let [report, setReport] = useState(<></>);

    useEffect(() => {
        switch (reportID) {
            case 1:
                setReport(<CategoricalReport />);
                break;
            case 2:
                setReport(<SummaryReport />);
                break;
            default:
                setReport(<></>);
        }
    },[reportID]);

    return (
        <div className={'h-screen [overflow-y:overlay]'}>
            <div className={'sm:w-2/3 md:w-1/2 2xl:w-3/5 mx-auto'}>
                {isMobile ? <Header /> : <></>} {report} {isMobile ? <Footer /> : <></>}
            </div>
        </div>
    );
};
import { useContext } from 'react';
import { relativeDay } from '../../../helpers/Helpers';
import { useNavigate } from 'react-router-dom';

import { TxnContext, SiteSettingsContext } from '../../contexts';
import { Version } from '../../extended/modals/Version';

export const DashboardTxnDetails = ({ className }) => {
    const { settings, onChangeSettings } = useContext(SiteSettingsContext);
    const { txn, setTxn, resetTxn, getDailySummary, summary } = useContext(TxnContext);
    const navigate = useNavigate();

    const isMobile = navigator.userAgentData.mobile;
    const mask = '***,***.**';

    const openTxnModal = (txn) => {
        setTxn({
            ...txn,
            txnModalOpen: true,
        });
    };

    const openTxn = (txn) => {
        setTxn({
            ...txn,
            method: 'put',
        });

        isMobile && navigate('/txn/' + txn.id);
    };

    return (
        <div className={className}>
            {summary.daily_summary &&
                Object.keys(summary.daily_summary).map((day, index) => (
                    <div className={'grid grid-cols-6'} key={index}>
                        <div
                            className={
                                'text-sm col-span-3  mt-4 pb-2 dark:text-gray-400 text-gray-500'
                            }>
                            {relativeDay(day)}
                        </div>
                        <div
                            className={
                                'text-sm col-span-3 text-right mt-4 pb-2 dark:text-gray-400 text-gray-500'
                            }>
                            LKR{' '}
                            {settings.privacy
                                ? parseFloat(summary.daily_summary[day].sub_total).lkr()
                                : mask}
                        </div>

                        <hr className={'col-span-6 dark:border-gray-400 mb-3'} />

                        {summary.daily_summary[day].txns.map((txn, index2) => (
                            <div key={index2} className={'col-span-6 grid grid-cols-6 cursor-pointer'} onClick={() => openTxn(txn)}>
                                <div className={'text-2xl text-outline'}>
                                    {txn.emoji}
                                </div>
                                <div className={'text-sm col-span-3 py-2 r'}>
                                    {txn.description}
                                </div>
                                <div
                                    className={
                                        'text-sm col-span-2 text-right py-2 ' +
                                        (txn.tx_type === 0 ? 'text-primary' : '')
                                    }>
                                    {settings.privacy ? parseFloat(txn.tx_amount).lkr() : mask}
                                </div>
                            </div>
                        ))}
                    </div>
                ))
            }
        </div>
    );
};
const initialState = {
    loading: false,
    isSuccess: false,
    errorData: null,
};

const userInit = {
    id: 0,
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    status: 0,
    level: 0,
    updated: '1970-01-01 00:00:00',
};

export const createPersistSlice = (set) => ({
    ...initialState,
    user: userInit,
    isAuthenticated: false,

    getUser: async (email) => {
        let url = process.env.REACT_APP_API_BASE;

        set((state) => ({ loading: true }));

        await fetch(`${url}/user/?email=${email}`, {
            method: 'GET',
        }).then(res => res.json()).then(user => {
            console.log(user);
            if (user.length > 0) {
                set({
                    success: true,
                    user: user[0],
                    isAuthenticated: true,
                });
            } else {
                set({
                    success: false,
                    isAuthenticated: false,
                });
            }
        }).catch(err => console.log(err)).finally(() =>
            set({
                loading: false,
            }),
        );
    },
    logOut: () => set((state) => ({ isAuthenticated: false, user: userInit })),
});
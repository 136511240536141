// https://zustand.docs.pmnd.rs/guides/slices-pattern

import { create, createStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { createPersistSlice } from './persistSlice';

export const useSessionStore = create(
    persist(
        (...a) => ({
            ...createPersistSlice(...a),
        }),
        {
            name: 'accounts-session-store',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);

export const persistSessionStore = useSessionStore;
export default useSessionStore;
import React, { useContext } from 'react';
import { MenuDesktop } from '../template/MenuDesktop';
import Footer from '../template/Footer';
import { Version } from '../extended/modals/Version';
import { SiteSettingsContext } from '../contexts';

const Layout = ({ aside, children }) => {
    const isMobile = navigator.userAgentData.mobile;
    const { settings, onChangeSettings } = useContext(SiteSettingsContext);

    return (
        <>
            <div className={`${isMobile ? 'flex flex-col' : 'grid grid-cols-4'}`}>
                {isMobile ? <></> : <MenuDesktop />}

                <main className={isMobile ? '' : 'col-span-2'}>{children}</main>

                {isMobile ? <Footer /> : <aside>{aside}</aside>}
            </div>
            {settings.newVersion && <Version />}
        </>
    );
};

export default Layout;
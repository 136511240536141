import React, { useEffect, useState } from 'react';
import ThemeToggle from '../../../template/ThemeToggle';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useSessionStore } from '../../../../stores/persit-session/persist-session-store';

export const Login = () => {
    const [loginResponse, setLoginResponse] = useState(null);
    const { user, isAuthenticated, getUser } = useSessionStore();

    useEffect(() => {
        console.log(user, isAuthenticated);
        if (isAuthenticated) {
            window.location.reload();
        }
    }, [user, isAuthenticated]);

    useEffect(() => {
        console.log(loginResponse);
        if (loginResponse && typeof loginResponse.clientId !== 'undefined') {
            const userInfo = jwtDecode(loginResponse.credential);

            getUser(userInfo.email).then(
                () => console.log('done'),
            );
        }
    }, [loginResponse]);

    const responseMessage = (response) => {
        //Observation: Can not jwt-decode here.
        setLoginResponse(response);
    };

    const errorMessage = (error) => {
        console.log(error);
    };

    return (
        <div className={'flex items-center justify-center flex-col gap-14 min-h-screen dark:bg-gray-800 dark:text-gray-100 transition-all'}>
            <h1 className={'font-thin text-3xl text-center mb-10 flex justify-center items-center'}>
                <span className={'font-bold pr-4 mr-4 border-r border-gray-500 text-4xl'}>accounts</span>
                <span>sign in</span>
            </h1>

            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />

            <div className={'bg-gray-100 dark:bg-gray-700 px-4 py-2 rounded-full mt-1'}>
                <ThemeToggle className={'text-xl'} disabled={false} />
            </div>
        </div>
    );
};
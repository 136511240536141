import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './helpers/PrivateRoute';
import NotFound from './components/template/NotFound';
import {
    TransferFunds,
    Reports,
    AddTxn,
    Dashboard,
} from './components/pages';

import { ThemeProvider, SiteSettingsProvider, TxnProvider } from './components/contexts';
import { AddTxnDesktop } from './components/pages/desktop/txn-add-web/AddTxnDesktop';
import Layout from './components/layout/Layout';

export default function App() {
    return (
        <ThemeProvider>
            <TxnProvider>
                <SiteSettingsProvider>
                    <BrowserRouter basename={''}>
                        <Routes>
                            <Route path={'/'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                                <Layout aside={<AddTxnDesktop />}>
                                    <Dashboard />
                                </Layout>
                            </PrivateRoute>} />

                            <Route path={'/reports'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                                <Layout aside={<AddTxnDesktop />}>
                                    <Reports />
                                </Layout>
                            </PrivateRoute>} />

                            <Route path={'/transfer'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                                <Layout aside={<AddTxnDesktop />}>
                                    <TransferFunds />
                                </Layout>
                            </PrivateRoute>} />

                            <Route path={'/txn/:id?'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                                <AddTxn />
                            </PrivateRoute>} />

                            <Route path={'/settings'} element={<PrivateRoute roles={['ROLE_ADMIN']} minlevel={4}>
                                <Dashboard />
                            </PrivateRoute>} />
                        </Routes>
                    </BrowserRouter>
                </SiteSettingsProvider>
            </TxnProvider>
        </ThemeProvider>
    );
}

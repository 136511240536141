import React, { useContext } from 'react';
import { PrivacyToggle } from '../../template/PrivacyToggle';
import { TxnContext, SiteSettingsContext } from '../../contexts';

export const DashboardSummary = () => {
    const { settings } = useContext(SiteSettingsContext);
    const { summary, getDailySummary, isTxnLoading } = useContext(TxnContext);

    const isMobile = navigator.userAgentData.mobile;
    const mask = '***,***.**';

    return (
        <section className={'pb-8 text-center sticky z-10 bg-white dark:bg-gray-800 pt-6 top-0 '}>
            {/*<div className={'flex gap-2 justify-end text-right mt-3 mb-8 mr-4'}>
                <i className={'la la-refresh p-3 rounded-full bg-gray-200 dark:bg-gray-700 cursor-pointer ' + (isTxnLoading ? 'la-spin' : '')} onClick={() => getDailySummary()} />
                <PrivacyToggle className={''} />
            </div>*/}

            <p className={'text-gray-400 text-sm'}>Spent this month</p>
            <h2 className={'text-2xl mt-1 mb-5 flex justify-center'}>
                <span className={'bg-gray-100 dark:bg-gray-700 py-1 px-8 rounded-full'}>
                    LKR {settings.privacy ? parseFloat(summary.spent_this_month).lkr() : mask}
                </span>
            </h2>

            <div className={'text-xs text-gray-400 mt-3 grid grid-cols-2 gap-3'}>
                <div className={'justify-self-end'}>
                    <span
                        className={
                            'text-primary bg-gray-100 dark:bg-gray-700 py-1 px-5 rounded-full whitespace-nowrap'
                        }>
                        <i className={'la la-download mr-1'} />
                        LKR {settings.privacy ? parseFloat(summary.earned_this_month).lkr() : mask}
                    </span>
                </div>
                <div className={'justify-self-start'}>
                    <span className={'bg-gray-100 dark:bg-gray-700 py-1 px-5 rounded-full whitespace-nowrap'}>
                        <i className={'la la-history mr-1'} />
                        LKR {settings.privacy ? parseFloat(summary.spent_last_month).lkr() : mask}
                    </span>
                </div>
            </div>
        </section>
    );
};
import ThemeToggle from './ThemeToggle';
import { logout } from '../../use-cases/common/utils';
import { Link, useLocation } from 'react-router-dom';

export const MenuDesktop = () => {
    const route = useLocation();

    const menu = [
        {
            name: 'Dashboard',
            icon: 'dashboard',
            path: '/',
        },
        {
            name: 'Investments',
            icon: 'swap_horiz',
            path: '/investments',
        },
        {
            name: 'Monthly Report',
            icon: 'segment',
            path: '/reports',
        },
    ];

    return (
        <div className={'h-screen overflow-y-auto'}>
            <div className={'flex justify-between items-center p-3'}>
                <div className={'flex items-center'}>
                    <div className={'p-3'}>
                        <img src={'img/nsd.jpg'} className={'rounded-full  border border-gray-700 dark:border-gray-200'}
                             style={{ width: '32px' }} />
                    </div>
                    <div className={'text-2xs'}>
                        <h2>Welcome back</h2>
                        <h3 className={'font-bold'}>Nipuna Dodantenna</h3>
                    </div>
                </div>
                <div className={'flex'}>
                    <ThemeToggle className={'text-2xl px-3'} />
                </div>
            </div>
            <div className={'flex flex-col justify-between h-[calc(100vh-80px)]'}>
                <div className={'p-3 flex gap-3 flex-col'}>
                    {
                        menu.map((menuItem, index) => (
                            <Link key={index} to={menuItem.path}>
                                <div className={'flex items-center gap-3 font-bold p-3 cursor-pointer rounded-lg hover:dark:bg-gray-700 hover:bg-gray-100' + (route.pathname === menuItem.path ? 'text-primary dark:bg-gray-700 bg-gray-100' : '')}>
                                    <span className="material-symbols-rounded">{menuItem.icon}</span> {menuItem.name}
                                </div>
                            </Link>
                        ))
                    }
                </div>
                <div className={'p-3 flex gap-3 flex-col'}>
                    <div className={'flex items-center gap-3 font-bold p-3 cursor-pointer rounded-lg hover:dark:bg-gray-700 hover:bg-gray-100'}>
                        <span className="material-symbols-rounded">settings</span> Settings
                    </div>
                    <div className={'flex items-center gap-3 font-bold p-3 cursor-pointer rounded-lg hover:dark:bg-gray-700 hover:bg-gray-100'} onClick={logout}>
                        <span className="material-symbols-rounded">logout</span> Logout
                    </div>
                </div>
            </div>
        </div>
    );
};